import mitt, { type Emitter } from "mitt";
import { io } from "socket.io-client";
import { AbortablePromise } from "@xuchaoqian/abortable-promise";
import { watchDebounced } from "@vueuse/core";
import { sleep } from "@/lib/util";

import {
  fetchPkceCredentials,
  fetchUserInfo,
  loginUrl,
} from "~/lib/streamlabs-api";
import { useToast } from "~/components/ui/toast";
import { deterministicUUIDv4 } from "~/lib/uuid";

import { generatePKCE } from "~/lib/security";
import Bindings, { openBrowserTo } from "~/lib/bindings";
import type { ChatQueryParamItem } from "~/pages/docks/chat-v2.vue";
import { reloadPluginDocks, restoreDocks } from "~/lib/docks";
import type { PDock } from "~/lib/obs/docks";

type StreamlabsWebsocketEvent =
  | { type: "donation"; for: string; message: any }
  | { type: "streamlabels"; message: { data: any } }
  | { type: "streamlabs_prime_subscribe"; message: { data: any } }
  | { type: "multistreamLive"; data: any }
  | StreamlabsSocketEvents["account_permissions_required"];

type StreamlabsWebsocketEventDonation = { for: string; message: any[] };
type StreamlabsWebsocketEventStreamlabels = {
  message: { [file: string]: string };
};

type GetInfoItem<T> = {
  is_live: boolean;
  chat_url: string;
  ccv: number;
  platform: T;
  platform_id: string;
  broadcast_id: null;
  channel_name: string;
};

export type MultistreamLive = {
  twitch?: GetInfoItem<"twitch">[];
  twitter?: GetInfoItem<"twitter">[];
  facebook?: GetInfoItem<"facebook">[];
  tiktok?: GetInfoItem<"tiktok">[];
  youtube?: GetInfoItem<"youtube">[];
};

export type StreamlabsSocketEvents = {
  event: StreamlabsWebsocketEvent;
  donation: StreamlabsWebsocketEventDonation;
  streamlabels: StreamlabsWebsocketEventStreamlabels;
  streamlabs_prime_subscribe: unknown;
  multistreamLive: MultistreamLive;
  account_permissions_required: {
    type: "account_permissions_required";
    event_id: string;
    for: string;
    message: {
      platform: "twitch_account";
      url: string;
      _id: string;
      priority: number;
    }[];
  };
};

export type StreamlabsSocketEmitter = Emitter<StreamlabsSocketEvents>;

const useStore = defineStore(
  "auth",
  () => {
    console.log("auth.store");
    const uuid = ref<string>();

    // set our uuid to be the hashed fingerprint visitorId
    useFingerprint().then((fingerprint) => {
      uuid.value = deterministicUUIDv4(fingerprint.visitorId);
    });

    const emitter = mitt<StreamlabsSocketEvents>();
    emitter.on("*", (type, e) => console.log("emitter", type, e));

    const { toast } = useToast();
    const app = useAppStore();
    const { track: $track } = useTrackingStore();
    const { public: $env } = useRuntimeConfig();
    const obs = useObsStore();

    const authResponseParams = ref<AuthResponseParams | undefined>();
    const userInfo = ref<UserInfoResponse>();
    const donationSettings = ref<DonationSettingsResponse>();
    const authedAt = ref<number>();

    const isBetaTester = ref(false);

    // if ($env.AUTH_RESPONSE_PARAMS) {
    //   setTimeout(() => {
    //     authResponseParams.value = JSON.parse($env.AUTH_RESPONSE_PARAMS);
    //     authedAt.value = Date.now();
    //   }, 500);
    // }

    const returnUrl = $env.AUTH_SUCCESS_RETURN_URL;

    const startResponseServer = async () => {
      const { port } = await Bindings.web.startServer(0, "/?", returnUrl);
      return port;
    };

    const logout = () => {
      authResponseParams.value = undefined;
    };

    let currentAbortController: undefined | AbortController;

    let abortablePromise: undefined | AbortablePromise<any>;

    const _doLoop = async <T extends Object>() => {
      let queryString = await Bindings.web
        .getAuthToken()
        .then(({ token }) => token);

      while (!queryString) {
        await sleep(500);
        queryString = await Bindings.web.getAuthToken().then(({ token }) => {
          return token;
        });
      }

      return [...new URLSearchParams(queryString).entries()].reduce(
        (q, [k, v]) => Object.assign(q, { [k]: v }),
        {} as T,
      );
    };

    const waitForQueryParams = async <T extends Object>() => {
      if (abortablePromise) {
        abortablePromise.abort();
      }

      await Bindings.web.clearAuthToken();

      abortablePromise = AbortablePromise.from(_doLoop<T>());

      return await abortablePromise;
    };

    const tryLogin = async (url: string = loginUrl.value) => {
      $track("login_attempted");

      const { challenge, verifier } = await generatePKCE();
      const port = await startResponseServer();

      const u = new URL(url);
      u.searchParams.set("origin", "obs-plugin");
      u.searchParams.set("code_challenge", challenge);
      u.searchParams.set("port", port.toString());

      openBrowserTo(u.toString());

      try {
        await waitForQueryParams<{ success: "true" }>();
        const response = await fetchPkceCredentials(verifier);
        authedAt.value = Date.now();
        authResponseParams.value = response.data.value?.data;
        $track("login_successful");
        await Bindings.obs.bring_front();
        await app.bringToFront();
      } catch (err) {
        console.error(err);
      }
    };

    const username = computed(() => userInfo.value?.display_name);
    const isUltra = computed(() => userInfo.value?.ultra);
    const avatar = computed(() => ({
      url: userInfo.value?.avatar,
    }));

    const tipPageUrl = computed(() => {
      if (!donationSettings.value?.donation_url) {
        return undefined;
      }

      return donationSettings.value.donation_url.replace(
        "beta.streamlabs",
        "streamlabs",
      );
    });

    const isAuthenticated = computed(() => !!authResponseParams.value);

    const sendAuthToDocks = (
      docks: PDock[],
      authResponse: AuthResponseParams,
    ) => {
      for (const dock of docks) {
        if (!dock.isSlabs) {
          continue;
        }

        console.log(dock.title, dock.url);

        if (!dock.url?.includes("loading=1")) {
          continue;
        }

        console.log(`sending payload to dock: ${dock.name}`, authResponse);

        dock.executeJavascript(
          `window.consumeAuthResponse(${JSON.stringify(authResponse)});`,
        );
      }
    };

    const $api = useApi();

    watch(
      authResponseParams,
      (is) => {
        $api.setBearerToken(is?.oauth_token);
      },
      {
        immediate: true,
      },
    );

    watch(
      [
        () => obs.docks,
        authResponseParams, //
      ],
      (
        [
          docksAre,
          authResponseParamsAre, //
        ],
        [
          docksWere,
          authResponseParamsWere, //
        ],
      ) => {
        if (docksAre && authResponseParamsAre) {
          sendAuthToDocks(docksAre, authResponseParamsAre);
        }

        // we were authed, now we are not
        if (!authResponseParamsAre && authResponseParamsWere) {
          console.warn(`!authResponseParamsAre && authResponseParamsWere`);
          reloadPluginDocks();
          return;
        }

        // we already had docks, so we've initialized already
        // this prevents infinite reloading
        if (docksWere) {
          console.warn(`docksWere`);
          return;
        }

        // docks are not loaded yet
        if (!docksAre) {
          console.warn(`!docksAre`);
          return;
        }

        // check if our docks exist
        if (!docksAre.find((dock) => dock.isSlabs)) {
          console.warn(`our docks don't exists, restoring!`);
          restoreDocks();
        } else {
          console.warn(`our docks exist, reloading them`);
          reloadPluginDocks();
        }
      },
      {
        immediate: true,
        // debounce: 350,
      },
    );

    // watch(
    //   [isAuthenticated, () => obs.docks],
    //   ([isAuthenticated, docksAre], [wasAuthenticated, docksWere]) => {
    //     if (docksWere) {
    //       console.warn(`docksWere`);
    //       return;
    //     }

    //     if (!docksAre) {
    //       console.warn(`!docksAre`);
    //       return;
    //     }

    //     if (wasAuthenticated === undefined) {
    //       console.warn(
    //         `isAuthenticated loading from storage: ${isAuthenticated}`,
    //       );
    //       return;
    //     }

    //     // check if our docks exist
    //     if (!docksAre.find((dock) => dock.isSlabs)) {
    //       console.warn(`our docks don't exists, restoring!`);
    //       restoreDocks();
    //     } else {
    //       console.warn(`our docks exist, reloading them`);
    //       reloadPluginDocks();
    //     }
    //   },
    //   {
    //     immediate: true,
    //     // debounce: 350,
    //   },
    // );

    const getMultistreamChatUrl = (
      platforms: MulitstreamChatSupportedPlatform[],
    ) => {
      if (!authResponseParams.value?.oauth_token) {
        return undefined;
      }

      const url = new URL(`https://streamlabs.com/embed/chat`);

      url.searchParams.set("oauth_token", authResponseParams.value.oauth_token);
      url.searchParams.set("mode", "night");
      url.searchParams.set("mobile", "0");
      url.searchParams.set("platforms", platforms.join(","));
      url.searchParams.set("send", "true");

      return url.toString();
    };

    const socketToken = computed(() => userInfo.value?.socket_token);
    const socket = ref<ReturnType<typeof io>>();
    const socketStatus = ref<
      "CONNECTING" | "CONNECTED" | "FAILED_TO_CONNECT"
    >();

    watch(
      socketToken,
      (is, was) => {
        if (is) {
          socketStatus.value = "CONNECTING";

          socket.value = io(
            `${$env.STREAMLABS_WS_BASE_PATH}/?token=${socketToken.value}`,
            {
              // forceNew: true,
              transports: ["websocket"],
              reconnection: true,
              reconnectionAttempts: 4,
              reconnectionDelay: 15,
            },
          );

          socket.value.on("connect_error", () => {
            $track("socket_connect_error");

            socketStatus.value = "FAILED_TO_CONNECT";
            toast({
              variant: "destructive",
              title: "Websocket Connection Error",
              description: `We we're unable to connect to the Streamlabs Websocket. Please restart OBS and contact support if the issue persist.`,
              duration: 60000,
            });
          });

          socket.value.on("connect", () => {
            socketStatus.value = "CONNECTED";
          });

          socket.value.on("event", (d: StreamlabsWebsocketEvent) => {
            emitter.emit("event", d);

            console.log("event", d);

            switch (d.type) {
              case "donation":
                emitter.emit("donation", { for: d.for, message: d.message });
                break;
              case "streamlabels":
                emitter.emit("streamlabels", { message: d.message.data });
                break;
              case "streamlabs_prime_subscribe":
                emitter.emit("streamlabs_prime_subscribe", {
                  message: d.message.data,
                });
                break;
              case "multistreamLive":
                emitter.emit("multistreamLive", d.data);
                break;

              case "account_permissions_required":
                emitter.emit("account_permissions_required", d);
            }
          });
        }
      },
      {
        immediate: true,
      },
    );

    const ensureUltra = (
      reason: DialogProps["ULTRA_REQUIRED"]["reason"],
      params: DialogProps["ULTRA_REQUIRED"]["params"],
    ) => {
      if (!isUltra.value) {
        app.showDialog("ULTRA_REQUIRED", { reason, params });

        $track("ultra_upsell_shown", { reason });

        return false;
      }

      return true;
    };

    emitter.on("streamlabs_prime_subscribe", () => {
      console.log(`emitter.on("streamlabs_prime_subscribe")`);
      $track("ultra_subscriptions");
      // refresh user data, they joined Ultra
      fetchUserInfo().then(({ data }) => {
        userInfo.value = data.value;
      });
    });

    return {
      isAuthenticated,
      getMultistreamChatUrl,
      username,
      avatar,
      isUltra,
      logout,
      tryLogin,

      ensureUltra,

      isBetaTester,
      authResponseParams,
      authedAt,
      userInfo,
      donationSettings,
      tipPageUrl,

      socket: emitter,
      socketStatus,

      uuid,

      waitForQueryParams,
      startResponseServer,

      dispose() {
        // ...
      },
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);

export const useAuthStore = useStore;

if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    useStore().dispose();
  });

  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}
