import Bindings from "~/lib/bindings";
import type { UltraCheckoutParams } from "~/lib/ultra";
import { generateRandomString, sleep } from "~/lib/util";

type DialogName =
  | "RESTART_OBS"
  | "ULTRA_REQUIRED"
  | "ONBOARDING"
  | "BETA_RESTRICTED"
  | "PLUGIN_OUTDATED"
  | "GO_LIVE"
  | "FEEDBACK";

export type DialogProps = {
  ULTRA_REQUIRED: {
    reason: UltraUpsellShownReason;
    params: UltraCheckoutParams;
  };
  PLUGIN_OUTDATED: {
    mode: "download_updater" | "restart_obs";
  };
};

type Dialogs = {
  RESTART_OBS: boolean;
  ULTRA_REQUIRED: false | DialogProps["ULTRA_REQUIRED"];
  ONBOARDING: boolean;
  BETA_RESTRICTED: boolean;
  PLUGIN_OUTDATED: false | DialogProps["PLUGIN_OUTDATED"];
  GO_LIVE: boolean;
  FEEDBACK: boolean;
};

type NonBooleanDialogKeys = {
  [K in keyof Dialogs]: Dialogs[K] extends boolean ? never : K;
}[keyof Dialogs];

type BooleanDialogKeys = {
  [K in keyof Dialogs]: Dialogs[K] extends boolean ? K : never;
}[keyof Dialogs];

type DialogConfirm = {
  type: "confirm";
  id: string;
  title: string;
  description: string;
  busy: boolean;

  cancelBtnFn: Function;
  cancelBtnText: string;

  confirmBtnFn: Function;
  confirmBtnText: string;
};

type DialogCommon = DialogConfirm;

const useStore = defineStore(
  "app",
  () => {
    console.log("app.store");
    const { public: $env } = useRuntimeConfig();
    const { t: $t } = useI18n();

    const name = computed(
      () => $env.APP_NAME || $t("Streamlabs Plugin for OBS"),
    );

    const defaultDimentions = computed(() => {
      return {
        width: $env.APP_DEFAULT_WIDTH * window.devicePixelRatio,
        height: $env.APP_DEFAULT_HEIGHT * window.devicePixelRatio,
      };
    });

    const dynamicZoomEnabled = ref(false);
    const wantsBetaFeaturesEnabled = ref(false);

    const resetDimentions = () => {
      // call old version
      Bindings.browser.resizeBrowser(
        defaultDimentions.value.width,
        defaultDimentions.value.height,
      );
    };

    const onboardingComplete = ref(false);
    const startStreamingFlowEnabled = ref(true);
    const isAlphaMode = ref(false);
    const leftNavPinned = ref(false);

    const flagDefaults = {
      hasSeenGoLiveFlow: false,
    };

    const flags = reactive({ ...flagDefaults });

    type Flags = typeof flags;
    type FlagName = keyof Flags;

    function setFlag<T extends FlagName>(flag: T, value: Flags[T]): void {
      flags[flag] = value;
    }

    function resetAllFlags() {
      Object.keys(flagDefaults).forEach((flag) => {
        const key = flag as FlagName;
        console.log(key, flags[key], flagDefaults[key]);
        flags[key] = flagDefaults[key];
      });
    }

    const dialogs = reactive<Dialogs>({
      RESTART_OBS: false,
      ULTRA_REQUIRED: false,
      ONBOARDING: !onboardingComplete.value,
      BETA_RESTRICTED: false,
      PLUGIN_OUTDATED: false,
      GO_LIVE: false,
      FEEDBACK: false,
    });

    onBeforeMount(() => {
      isAlphaMode.value =
        window.location.host.startsWith("localhost") ||
        window.location.host.startsWith("obs-plugin.streamlabs.dev") ||
        window.location.host.startsWith("obs-plugin.streamlabs.site");

      dialogs.GO_LIVE = false;
      dialogs.RESTART_OBS = false;
      dialogs.ULTRA_REQUIRED = false;
      dialogs.PLUGIN_OUTDATED = false;
      dialogs.FEEDBACK = false;
      dialogs.ONBOARDING = !onboardingComplete.value;
    });

    function showDialog<T extends BooleanDialogKeys>(dialog: T): void;
    function showDialog<T extends NonBooleanDialogKeys>(
      dialog: T,
      value: Dialogs[T],
    ): void;

    function showDialog<T extends keyof Dialogs>(
      dialog: T,
      value: Dialogs[T] = true as Dialogs[T],
    ): void {
      dialogs[dialog] = value;

      if (value) {
        bringToFront();
      }
    }

    const hideDialog = (dialog: DialogName) => {
      dialogs[dialog] = false;
    };

    const commonDialogs = reactive<DialogCommon[]>([]);

    type ConfirmContext = (context: {
      close: () => void;
      setBusy: (busy: boolean) => void;
    }) => void;

    const showConfirmDialog = ({
      title,
      description,
      confirmBtnText = "Confirm",
      cancelBtnText = "Cancel",
      onConfirm,
      onCancel,
    }: {
      title: string;
      description: string;
      onConfirm?: ConfirmContext;
      onCancel?: ConfirmContext;
      confirmBtnText?: string;
      cancelBtnText?: string;
    }) => {
      const id = generateRandomString();
      const close = () => {
        const idx = commonDialogs.findIndex((item) => item.id === id);

        if (idx >= 0) {
          commonDialogs.splice(idx, 1);
        }
      };

      const setBusy = (busy: boolean) => {
        const idx = commonDialogs.findIndex((item) => item.id === id);
        if (idx >= 0) {
          commonDialogs[idx].busy = busy;
        }
        // ...
      };

      commonDialogs.push({
        type: "confirm",
        id,
        title,
        description,
        busy: false,

        cancelBtnText,
        cancelBtnFn: onCancel ? () => onCancel({ close, setBusy }) : close,

        confirmBtnText,
        confirmBtnFn: onConfirm ? () => onConfirm({ close, setBusy }) : close,
      });
    };

    const bringToFront = () => {
      Bindings.browser.bringToFront();
      Bindings.browser.setHiddenState(false);
    };

    const hide = () => {
      Bindings.browser.setHiddenState(true);
    };

    const versionInfo = ref<VersionInfo>();
    const metaPublish = ref<VersionManifest | null>(null);
    const requiresUpdate = ref(false);

    const checkForUpdates = async () => {
      metaPublish.value = await useFetch<VersionManifest>(
        $env.PLUGIN_VERSION_MANIFEST_URL,
      ).then((res) => res.data.value);

      versionInfo.value = await Bindings.sl.getVersionInfo();

      if (+versionInfo.value.rev < $env.PLUGIN_VERSION_MIN) {
        showDialog("PLUGIN_OUTDATED", {
          // if we already needed an update, it's likely that restart didn't auto update
          mode: requiresUpdate.value ? "download_updater" : "restart_obs",
        });

        requiresUpdate.value = true;
      } else {
        requiresUpdate.value = false;
      }
    };

    return {
      dialogs,
      showDialog,
      hideDialog,

      commonDialogs,
      showConfirmDialog,

      name,
      resetDimentions,
      bringToFront,
      hide,
      onboardingComplete,
      startStreamingFlowEnabled,
      dynamicZoomEnabled,
      wantsBetaFeaturesEnabled,

      isAlphaMode,
      leftNavPinned,

      versionInfo,
      metaPublish,
      checkForUpdates,
      requiresUpdate,

      // flags: readonly(flags),
      flags,

      setFlag,
      resetAllFlags,

      dispose() {
        console.log("dispose");
        // ...
      },
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);

export const useAppStore = useStore;

if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    useStore().dispose();
  });

  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}
