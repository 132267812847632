import { asyncComputed, useMemoize } from "@vueuse/core";
import type { AsyncData } from "nuxt/app";
import { equalityRef } from "~/lib/util";

export const wrap = <Data, Error>(d: AsyncData<Data, Error>) => {
  !["pending", "success"].includes(d.status.value) && d.execute();
  return d;
};

export const useApiStore = defineStore("api", () => {
  const auth = useAuthStore();
  const bearerToken = computed(() => auth.authResponseParams?.oauth_token);
  const { public: $env } = useRuntimeConfig();

  const headers = equalityRef<HeadersInit>(
    auth.authResponseParams?.oauth_token
      ? {
          Authorization: `Bearer ${auth.authResponseParams.oauth_token}`,
        }
      : {},
  );

  const f = <T>(...args: Parameters<typeof useFetch<T>>) =>
    useFetch<T>(args[0], {
      baseURL: $env.STREAMLABS_API_BASE_PATH,
      headers,
      ...(args[1] || {}),
    });

  const w = <T>(...args: Parameters<typeof useFetch<T>>) => {
    const d = f(args[0], {
      server: false,
      retry: false,
      lazy: true,
      immediate: false,
      ...(args[1] || {}),
    });

    return {
      pending: d.pending,
      execute: d.execute,
      refresh: async () => {
        await d.refresh();
        return d.data.value;
      },
      data: asyncComputed(
        () => {
          if (!auth.authResponseParams?.oauth_token) {
            return null;
          }

          headers.value = {
            Authorization: `Bearer ${auth.authResponseParams.oauth_token}`,
          };

          if (!["pending", "success", "error"].includes(d.status.value)) {
            d.execute();
          }

          return d.data.value;
        },
        null,
        { lazy: true },
      ),
    };
  };

  return {
    fetch: f,
    streamLabels: {
      appSettings: w<StreamLabelsAppSettingsResponse>(
        `/api/v5/slobs/stream-labels/app-settings`,
      ),
      files: w<StreamLabelsFilesResponse>(
        `/api/v5/obs-plugin/stream-labels/files`,
      ),
      settings: w<StreamLabelsSettingsResponse>(
        `/api/v5/slobs/stream-labels/settings`,
      ),
    },
    multistream: {
      ingest: w<RstIngestResponse>(`/api/v5/obs-plugin/rst/ingest`),
      user: {
        platforms: w<RstUserPlatformsResponse>(
          `/api/v5/obs-plugin/rst/user/platforms`,
        ),
        profile: w<RstUserProfileResponse>(
          `/api/v5/obs-plugin/rst/user/profile`,
        ),
        targets: w<RstUserTargetsResponse>(
          `/api/v5/obs-plugin/rst/user/targets`,
        ),
      },
    },
    golive: {
      youtube: {
        info: w<GoLiveYouTubeInfoResponse>(
          "/api/v5/obs-plugin/go-live/youtube/info",
        ),
      },
    },
    platformInfo: w<UserPlatformInfoResponse>(
      `/api/v5/obs-plugin/user/platform-info`,
    ),
  };
});
