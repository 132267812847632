import mitt from "mitt";
import pkg from "@/package.json";

export type UltraUpsellShownReason =
  | "overlay_install"
  | "widget_theme_install"
  | "multistream_toggle"
  | "go_live_multiple_destinations";

type TrackingEvents = {
  //
  // implemented
  plugin_start: undefined;
  ultra_subscriptions: undefined;
  login_attempted: undefined;
  login_successful: undefined;
  left_nav_clicked: { nav_item_name: string };
  user_menu_item_clicked: { menu_item_name: string };
  overlays_item_clicked: { overlay_id: number };
  overlays_install_clicked: { overlay_id: number };
  widget_themes_item_clicked: { widget_theme_id: number };
  widget_themes_install_clicked: { widget_theme_id: number };
  widgets_add_clicked: { widget_name: string };
  widgets_settings_clicked: { widget_name: string };
  settings_uninstall_help_clicked: undefined;
  onboarding_canceled: undefined;
  onboarding_ultra: undefined;
  onboarding_free: undefined;
  socket_connect_error: undefined;
  test_event: {
    test_event_platform?: string;
    test_event_type: string;
  };
  ultra_upsell_shown: {
    reason: UltraUpsellShownReason;
  };

  // user is actually live in OBS
  go_live: undefined;

  // plugin "Go Live" flow opened
  going_live_via_plugin_opened: undefined;

  // user clicked "Start Streaming" button in "Go Live" flow
  going_live_via_plugin_attempted: undefined;

  // stream starting via "Go Live" flow
  going_live_via_plugin_started: {
    type: "basic" | "multistream";
  };

  // stream starting via OBS, not our plugin
  going_live_via_obs_started: undefined;

  stopped_streaming: undefined;

  ping: undefined;

  //
  // not implemented yet
  plugin_install: undefined;
  plugin_uninstall: undefined;
  onboarding_step_completed: { step_name: string };
  overlay_install_with_widgets: undefined;
  overlay_installed: { overlay_id: number };
  widget_theme_installed: { widget_theme_id: number };
  multistream_toggle_enable: undefined;
  multistream_account_enabled: { platform: string };
};

declare global {
  interface Window {
    dataLayer?: object[];
  }
}

interface R2D2Event {
  product: string;
  version: string;
  event: string;
  value: any;
  time?: Date;
  count?: number;
  uuid?: string;
  saveUser?: boolean;
  userId?: number;
}

const trackEvent = (eventData: Object) => {
  window.dataLayer?.push(eventData);
};

const r2d2 = (projectId: string, event: R2D2Event | R2D2Event[]) => {
  const events = Array.isArray(event) ? event : [event];

  useFetch(`https://r2d2.streamlabs.com/projects/${projectId}/data/ping`, {
    method: "POST",
    body: { events },
  });
};

export const useTrackingStore = defineStore("tracking-store", () => {
  console.log("tracking.store");
  const authStore = useAuthStore();
  const apiStore = useApiStore();

  const r2d2Queue = reactive<{ event: string; value: any }[]>([]);

  watch(
    [() => authStore.uuid, r2d2Queue],
    () => {
      if (!authStore.uuid) {
        return;
      }

      if (!$env.R2D2_PROJECT_ID) {
        return;
      }

      const eventsToSend = r2d2Queue.splice(0);

      if (!eventsToSend.length) {
        return;
      }

      r2d2(
        $env.R2D2_PROJECT_ID,
        eventsToSend.map((event) => ({
          ...event,
          uuid: authStore.uuid,
          product: pkg.name,
          version: pkg.version,
          userId: authStore.userInfo?.id,
        })),
      );
    },
    { immediate: true },
  );

  const { public: $env } = useRuntimeConfig();

  const dataLayer: { [key: string]: string | boolean }[] = [
    {
      event: "OneTrustGroupsUpdated",
      OneTrustActiveGroups: "C0001,C0002,C0003,C0004,C0005",
    },
    { blocked_tags: "GA4,AzAds,CIO,DDM,SAQ,TT,OT" },
    // { wait_for_user_id: true },
  ];

  console.log(`env: ${$env.APP_ENV}`);

  if ($env.APP_ENV !== "production") {
    dataLayer.push({ debug_mode: true });
  }

  if (!$env.R2D2_PROJECT_ID) {
    console.warn("R2D2_PROJECT_ID not set, skipping R2D2 initialization");
  }

  if (!$env.APP_GTM_ID) {
    console.warn("APP_GTM_ID not set, skipping GTM initialization");
  } else {
    /*
    useHead({
      script: [
        `
          window.dataLayer = ${JSON.stringify(dataLayer)};

          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", ${JSON.stringify(
            $env.APP_GTM_ID,
          )});
          `,
      ],
    });
    */
  }

  const emitter = mitt<TrackingEvents>();

  emitter.on("*", (type, additional) => {
    if ($env.APP_GTM_ID) {
      trackEvent({
        event: ["obs_plugin_", type].join(""),
        ...(additional || {}),
      });
    }

    r2d2Queue.push({
      event: type,
      value: additional || 1,
    });
  });

  // tracking for salman
  const streamingType = ref<"basic" | "multistream">();

  emitter.on("stopped_streaming", async () => {
    if (streamingType.value === "multistream") {
      await apiStore.fetch<unknown>(`/api/v1/rst/log`, {
        method: "POST",
        body: { event: "rs.stream.ended", data: { source: "obs.plugin" } },
      });
    }
  });

  emitter.on("going_live_via_plugin_started", async ({ type }) => {
    streamingType.value = type;

    if (streamingType.value === "multistream") {
      await apiStore.fetch<unknown>(`/api/v1/rst/log`, {
        method: "POST",
        body: { event: "rs.stream.started", data: { source: "obs.plugin" } },
      });
    }
  });

  return {
    track: emitter.emit,
  };
});
