const bearerToken = ref<string>();

export const useApi = () => {
  const { public: $env } = useRuntimeConfig();

  const $fetch = useFetch;

  const fetch = <T>(
    path: string,
    options: Parameters<typeof $fetch<T>>[1] = {},
  ) => {
    return $fetch<T>(path, {
      baseURL: $env.STREAMLABS_API_BASE_PATH,
      ...options,
      headers: {
        ...(bearerToken.value && {
          Authorization: `Bearer ${bearerToken.value}`,
        }),
        ...(options.headers ? unref(options.headers) : {}),
      },
      server: false,
    });
  };

  return {
    setBearerToken(token?: string) {
      bearerToken.value = token;
    },
    fetch,
  };
};
